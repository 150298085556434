import { makeStyles, withStyles, ThemeProvider } from "@material-ui/core/styles";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Button from "@material-ui/core/Button";
import { useState } from "react";
import { useHistory } from "react-router-dom"; 
import './styles/product.css';
import CalculadoraResult from "./components/CalculadoraResult";
import { createTheme } from "@mui/material";
import Snackbar from '@material-ui/core/Snackbar';

export function InstinctFormView (props){

    const history = useHistory();
    const  {
        idTienda,
        nameTienda,
        infoProducto
    } = props;

    const useStyles = makeStyles((theme) => ({
        root: {
          marginTop: '20px',
          backgroundColor: '#fafafa',
          margin: '0 auto 0px',
          textAlign: 'center',
          width: '50%',
          minWidth: '300px',
          maxWidth:'450px'
        },
        imageList: {
          flexWrap: 'nowrap',
          transform: 'translateZ(0)',
        },
        labelForm: {
            display: 'block',
            width: '100%',
            textAlign: 'left'
        },
        inputForm: {
            width: '100%',
            height: '30px',
            border: '0.5px solid #c1c1c1',
            padding: '0 5px 0 5px',
            '&:focus': {
                outlineColor: '#57a7ff'
            },
            borderRadius: '5px'
        },
        mb15: {
            marginBottom: '15px'
        },
        optionsContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
        },
        optionItem: {
            height: '110px',
            width: '110px',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '2px solid #c1c1c1',
            borderRadius: '5px',
            color: '#717171',
        },
        mar0: {
            margin: '0'
        },
        '@media (max-width: 730px)': {
            'optionItem': {
                width: '90px',
                height: '90px',
                fontSize: '11px'
            }
        },
        smallHint: {
            color: '#838383',
            fontSize: '10px'
        },
        hidden: {
            visibility: 'hidden'
        },
        buttonsContainer: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        itemOptionSelected: {
            border: "2px solid #57a7ff"
        },
        errorSpan: {
            display: 'block',
            textAlign: 'left',
            color: '#ff0000'
        },
        dn: {
            display: 'none'
        },
        radioBtn1: {
            '&:checked': {
                border: "2px solid #57a7ff"
            }
        }
    }));

    const classes = useStyles();

    const ColorButton = withStyles((theme) => ({
        root: {
          color: theme.palette.getContrastText('#FCBD00'),
          borderRadius: '10px',
          marginTop:'10px',
          backgroundColor: '#FCBD00',
          '&:hover': {
            backgroundColor: '#FCBD00',
          },
          width: '48%',
        },
      }))(Button);

      const ColorButtonNotPrimary = withStyles((theme) => ({
        root: {
          color: theme.palette.getContrastText('#FCBD00'),
          borderRadius: '10px',
          marginTop:'10px',
          backgroundColor: '#C1C1C1',
          '&:hover': {
            backgroundColor: '#C1C1C1',
          },
          width: '48%'
        },
      }))(Button);

    const [formData, setFormData] = useState({
        petName: '',
        petWeight: '',
        petAdultWeight: ''
    });

    const [dataFromChild, setDataFromChild] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [petAge, setPetAge] = useState('');
    const handleCallback = (data) => {
        setDataFromChild(data);
        setShowComponent(false);
      };

    const form2 = {
        petAge: '',
        petWeightStatus: '',
        petWorkoutTime: '',
    };

    console.log(infoProducto);

      const selectOption = (option, section) => {
        let element = option.target;
        if (element && element.tagName.toLowerCase() === 'input')
            return;
        const options = document.querySelectorAll(`.item-option${section === 1 ? '' : section}`);
        if (options && options.length > 0) {
            options.forEach(function(elem) {
                elem.classList.remove(classes.itemOptionSelected);
            });
            let idElem = '';
            let elementValue = '';
            if (element && element.tagName.toLowerCase() === 'p') {
                element.parentElement.classList.add(classes.itemOptionSelected);
                idElem = element.parentElement.id;
                elementValue = element.parentElement.getAttribute('aria-valuetext');
            }
            else {
                element.classList.add(classes.itemOptionSelected);
                idElem = element.id;
                elementValue = element.getAttribute('aria-valuetext');
            }
            if (idElem && idElem !== '') {
                form2[idElem] = elementValue;
            }
        }
      };

    const [errores, setErrores] = useState({});
    const [dataCalculadora, setDataCalculadora] = useState(null);
    const [showComponent, setShowComponent] = useState(false);

    const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.id]: e.target.value
        });
    };

    const changeAge = (e) => {
        setPetAge(e.target.value);
    }
    const calculate = async () => {
        try {
            const dataInstinct = JSON.parse(localStorage.getItem('productInstinct'));
            const data = {
                product_id: dataInstinct.product_id,
                specie: 'perro',
                age: [...document.getElementsByName('age')].filter(x => x.checked)[0].value,
                mass: [...document.getElementsByName('weight')].filter(x => x.checked)[0].value,
                weight: formData.petWeight,
                lifestyle: [...document.getElementsByName('workout')].filter(x => x.checked)[0].value,
                adultWeight: formData.petAdultWeight
            };
            const resp = await fetch('https://app.petmarkt.com.mx/api/feeding-calculator/calculate', { headers: {
                'content-type': 'application/json',
                'x-shopify-shop-domain': 'instinctpetfoodmx.myshopify.com',
                
            }, method: 'POST', body: JSON.stringify(data)});
            if (resp.status === 200) {
                const data = await resp.json();
                console.log(data);
                return data;
            } else
                console.log('error');
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    const sumbit = async () => {
        const erroresNuevos = {};
        if (formData.petName.trim() === '' || formData.petName.length === 0) {
            erroresNuevos.petName = 'El nombre de la mascota es requerido';
        }
        if (formData.petWeight.trim() === '' || formData.petWeight.trim().length === 0)
            erroresNuevos.petWeight = 'Este campo es requerido';
        if (!/^\d+(\.\d+)?$/.test(formData.petWeight))
            erroresNuevos.petWeight = 'Este campo debe ser numérico';
        if ([...document.getElementsByName('age')].filter(x => x.checked).length === 0)
            erroresNuevos.petAge = 'Por favor, selecciona una opción';
        if (document.getElementById('ageAdult').checked) {
            if (formData.petAdultWeight.trim() === '' || formData.petAdultWeight.trim().length === 0)
                erroresNuevos.petAdultWeight = 'Este campo es requerido';
            if (!/^\d+(\.\d+)?$/.test(formData.petAdultWeight))
                erroresNuevos.petAdultWeight = 'Este campo debe ser numérico';
        }
        if ([...document.getElementsByName('weight')].filter(x => x.checked).length === 0)
            erroresNuevos.petWeightStatus = 'Por favor, selecciona una opción';
        if ([...document.getElementsByName('workout')].filter(x => x.checked).length === 0)
            erroresNuevos.petWorkout = 'Por favor, selecciona una opción';
        setErrores(erroresNuevos);
        if (Object.keys(erroresNuevos).length === 0) {
          setLoading(true);
          console.log('Formulario enviado:', formData);
          const dataInstinct = await calculate();
          setLoading(false);
          if (dataInstinct) {
            setDataCalculadora(dataInstinct);
            setShowComponent(true);
          } else {
            setDataCalculadora(null);
            setShowComponent(false);
            setSnackBarMessage('No se puede calcular, intenta nuevamente');
            setOpenSnackBar(true);
          }
        }
    };

    const WarningTheme = createTheme({
        overrides: {
          MuiSnackbarContent: {
            root: {
              backgroundColor: '#FCBD00',
              color:'#454545'
            }
          }
        }
      });

    const regresar = () => {
        history.push(`/lps/t/${idTienda}/p/${infoProducto.code}`);
    };
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    };

    if (!infoProducto)
        return <></>;

    return (
        <div>
            <Header tienda={nameTienda}></Header>
            { !showComponent && !dataFromChild  &&(<div className={classes.root}>
                <h2><span style={{ fontWeight: 'bolder' }}>{infoProducto.summary}</span><br /></h2>
                <div className={classes.mb15}>
                    <label className={`${classes.labelForm} ${classes.mb15}`}>¿Cuál es el nombre de tu mascota?</label>
                    <input type="text" maxLength={30} id="petName" value={formData.petName} onChange={handleChange} className={classes.inputForm}/>
                    {errores.petName && <span className={classes.errorSpan}><small>{errores.petName}</small></span>}
                </div>
                <div className={classes.mb15}>
                    <label className={`${classes.labelForm} ${classes.mb15}`}>¿Cuántos años tiene {formData.petName}?</label>
                    {errores.petAge && <span className={classes.errorSpan}><small>{errores.petAge}</small></span>}
                    <div className={classes.optionsContainer}>
                        <input type="radio" name="age" value="puppy" id="ageAdult" className="dn radioBtn1" onChange={changeAge}/>
                        <label className={`item-option-selected1 item-option`} htmlFor="ageAdult" aria-valuetext="puppy">
                            <p className={classes.mar0}>Cachorro <br/>1-11 meses</p>
                        </label>
                        <input type="radio" name="age" value="adult" id="ageAdult1" className="dn radioBtn1" onChange={changeAge} />
                        <label className={`item-option-selected1 item-option`} htmlFor="ageAdult1" aria-valuetext="adult">
                            <p className={classes.mar0}>Adulto <br/>1-6 años</p>
                        </label>
                        <input type="radio" name="age" value="senior" id="ageAdult2" className="dn radioBtn1" onChange={changeAge}/>
                        <label className={`item-option-selected1 item-option`} htmlFor="ageAdult2" aria-valuetext="senior">
                            <p className={classes.mar0}>Senior <br/>7 o más años</p>
                        </label>
                    </div>
                </div>
                <div className={classes.mb15}>
                    <label className={`${classes.labelForm} ${classes.mb15}`}>¿Cuánto pesa {formData.petName}? <small className={classes.smallHint}>Peso en Kg</small></label>
                    <input type="text" inputMode="numeric" autoComplete="off" maxLength={3} id="petWeight" value={formData.petWeight} onChange={handleChange} className={classes.inputForm}/>
                    {errores.petWeight && <span className={classes.errorSpan}><small>{errores.petWeight}</small></span>}
                </div>
                { petAge === 'puppy' && <div className={classes.mb15}>
                    <label className={`${classes.labelForm} ${classes.mb15}`}>¿Cuánto se espera que pese {formData.petName} como adulto? <small className={classes.smallHint}>Peso en Kg</small></label>
                    <input type="text" inputMode="numeric" autoComplete="off" maxLength={3} id="petAdultWeight" value={formData.petAdultWeight} onChange={handleChange} className={classes.inputForm}/>
                    {errores.petAdultWeight && <span className={classes.errorSpan}><small>{errores.petAdultWeight}</small></span>}
                    </div>
                }
                <div className={classes.mb15}>
                    <label className={`${classes.labelForm} ${classes.mb15}`}>{formData.petName} está....</label>
                    {errores.petWeightStatus && <span className={classes.errorSpan}><small>{errores.petWeightStatus}</small></span>}
                    <div className={classes.optionsContainer}>
                        <input type="radio" name="weight" value="underweight" id="petWeightStatus" className="dn radioBtn1"/>
                        <label className={`item-option-selected1 item-option`} htmlFor="petWeightStatus" aria-valuetext="underweight">
                            <p className={classes.mar0}>Por debajo<br/>de su peso</p>
                        </label>
                        <input type="radio" name="weight" value="ideal" id="petWeightStatus1" className="dn radioBtn1"/>
                        <label className={`item-option-selected1 item-option`} htmlFor="petWeightStatus1" aria-valuetext="ideal">
                            <p className={classes.mar0}>En el peso<br/>ideal</p>
                        </label>
                        <input type="radio" name="weight" value="overweight" id="petWeightStatus2" className="dn radioBtn1"/>
                        <label className={`item-option-selected1 item-option`} htmlFor="petWeightStatus2" aria-valuetext="overweight">
                            <p className={classes.mar0}>Con<br/>sobrepeso</p>
                        </label>
                    </div>
                </div>
                <div className={classes.mb15}>
                    <label className={`${classes.labelForm} ${classes.mb15}`}>¿Cuánto tiempo se ejercita {formData.petName}?</label>
                    {errores.petWorkout && <span className={classes.errorSpan}><small>{errores.petWorkout}</small></span>}
                    <div className={classes.optionsContainer}>
                        <input type="radio" name="workout" value="sedentary" id="petWorkoutStatus" className="dn radioBtn1"/>
                        <label className={`item-option-selected1 item-option`} aria-valuetext="sedentary" htmlFor="petWorkoutStatus">
                            <p className={classes.mar0}>Menos de<br/>2 hrs al día</p>
                        </label>
                        <input type="radio" name="workout" value="active" id="petWorkoutStatus1" className="dn radioBtn1"/>
                        <label className={`item-option-selected1 item-option`} aria-valuetext="active" htmlFor="petWorkoutStatus1">
                            <p className={classes.mar0}>Más de 2<br/>hrs al día</p>
                        </label>
                        <div className={`${classes.optionItem} ${classes.hidden}`}>
                            <p className={classes.mar0}>Más de 2<br/>hrs al día</p>
                        </div>
                    </div>
                </div>
                <div className={classes.buttonsContainer}>
                    <ColorButtonNotPrimary onClick={regresar} disabled={isLoading}><strong>Regresar</strong></ColorButtonNotPrimary>
                    <ColorButton onClick={sumbit} disabled={isLoading}><strong>{isLoading ? 'Calculando...' : 'Continuar'}</strong></ColorButton>
                </div>
            </div>
            )}

            { showComponent && (<CalculadoraResult sendDataToParent={handleCallback} data={dataCalculadora}></CalculadoraResult>) }
            <ThemeProvider theme={WarningTheme}>
            <Snackbar
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                    }}
                    open={openSnackBar}
                    autoHideDuration={5000}
                    message={snackBarMessage}
                    onClose={handleCloseSnackBar}
                />
        </ThemeProvider>
            <Footer></Footer>
        </div>
    );
};