import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"; 
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '20px',
    backgroundColor: '#fafafa',
    margin: '0 auto 0px',
    textAlign: 'center',
    width: '50%',
    minWidth: '300px',
    maxWidth:'450px'
  },
  imageList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
}));

export default function Product(props) {

  const history = useHistory();
  const { data } = props;
  const classes = useStyles();
  const products = ['114124'];
  const [formulario, toggleFormulario] = useState(false);

  const [dataInstinct, setData] = useState(null);

  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText('#FCBD00'),
      borderRadius: '10px',
      marginTop:'10px',
      backgroundColor: '#FCBD00',
      '&:hover': {
        backgroundColor: '#FCBD00',
      },
    },
  }))(Button);

  const clickFormulario = () => {
    /* const path = window.location.pathname.split('/');
    const indexOf = path.indexOf('p');
    if (indexOf !== -1)
      path[indexOf + 1] = dataInstinct.product_id; */
    history.push(`${window.location.pathname}/instinct-form`);
  };

  useEffect(() => {
    if (data) {
      const getProductInstinct = async () => {
        try {
          const resp = await fetch(`https://app.petco.com.mx/calculadora_alimentos/product-instinct/${data.code}`, {
            headers: {
              'content-type': 'application/json',
              'x-api-pc-wss': 'bbdcaf67935030c9ca3f39219e1a30f1ec0f8dffbd4021324acb258daf8877bb'
            }
          });
          if (resp.status === 200) {
            const data2 = await resp.json();
            setData(data2);
            window.localStorage.setItem('productInstinct', JSON.stringify(data2));
          }
        } catch (error) {
          console.log(error);
        }
      };
      getProductInstinct();
    }
  }, [data]);

  if (!data)
    return (<></>)
  
  if (dataInstinct) {
    return (
      <div className={classes.root}>
        <h2><span style={{ fontWeight: 'bolder' }}>{data.summary}</span><br /></h2>
        <ImageGallery showFullscreenButton={false} showPlayButton={false} items={data.galleryImages} />
        <p style={{ fontSize: '18px' }}>Descubre la cantidad de alimento que tu mascota necesita</p>
        <ColorButton onClick={clickFormulario}><strong>Comenzar cuestionario</strong></ColorButton>
      </div>
    )
  }
  
  return (
    <div className={classes.root}>
      <h2><span style={{ fontWeight: 'bolder' }}>{data.summary}</span><br /></h2>
      <ImageGallery showFullscreenButton={false} showPlayButton={false} items={data.galleryImages} />
    </div>
  )
}